var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "wuwow-card reg-notificationDetail",
    },
    [
      _c(
        "div",
        {
          staticClass: "wuwow-card-head",
        },
        [
          _c(
            "h4",
            {
              staticClass: "h4-notificationDetail-title",
            },
            [_vm._v(_vm._s(_vm.notificationData.title))]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "wuwow-card-body",
        },
        [
          _c("div", {
            staticClass: "are-notificationDetail-content",
            domProps: {
              innerHTML: _vm._s(_vm.notificationData.content),
            },
          }),
        ]
      ),
      _vm.notificationData.file && _vm.notificationData.file.length > 0
        ? _c(
            "div",
            {
              staticClass: "are-notificationDetail-attachment",
            },
            [
              _c(
                "span",
                {
                  staticClass: "txt-attachment-title",
                },
                [_vm._v(_vm._s(_vm.$t("attackDownload")) + "：")]
              ),
              _vm._l(_vm.NotificationDetail.file, function (item, index) {
                return _c(
                  "a",
                  {
                    key: index,
                    staticClass: "lnk-attachment",
                    attrs: {
                      href: item.url,
                      target: "_blank",
                    },
                  },
                  [_vm._v(_vm._s(item.name))]
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _c(
        "button",
        {
          staticClass: "my-3 ml-3 btn btn-outline-primary pull-right",
          attrs: {
            type: "button",
          },
          on: {
            click: function ($event) {
              return _vm.back()
            },
          },
        },
        [_vm._v(_vm._s(_vm.$t("router_back")))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }